

import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddPriceTemplateModal from "@/views/apps/research/AddPriceTemplateModal.vue";
import AddPriceReadingModal from "@/components/modals/forms/AddPriceReadingModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import AddPriceAttributeModal from "@/components/modals/forms/AddPriceAttributeModal.vue";

import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import {ActionsRe} from "@/store/enums/StoreResearchEnums";
import { Modal } from "bootstrap";
import UploadEntityModal from "@/components/modals/forms/UploadEntityModal.vue";
import { status_type } from "@/core/data/genericData";

export default defineComponent({
  name: "apps-upload-listing",
  components: {
    ExportCustomerModal,
   // AddPriceTemplateModal, 
    //AddPriceReadingModal,
    //AddPriceAttributeModal,
        UploadEntityModal,
  },
   props: 
  {
      id: { required: true },
  },
  // methods:{
  //   onChange(){
  //     (document.getElementById("page_id") as HTMLInputElement).innerText = ''
  // }
  // },
  setup(props) {
    const count = ref(0);
    const store = useStore();
    const checkedCompany = ref([]);    
    const loadingData = ref<boolean>(false);
    const searchTearm = ref('');
    const searchTearmV = ref('');
    const searchTearmU = ref('');
    const viewDataModalRef = ref<null | HTMLElement>(null);
    var formDataViewValue = ref({ cust_select: "",});
    const status_list_data = status_type;
    const product_attr_modal = (price_template_id) => {
      const modal = new Modal(document.getElementById("kt_price_modal_attribute"));
      modal.show();
      (document.querySelector('#price_template') as HTMLInputElement).value = price_template_id;
    }



    interface Grade {
      record_id: number,          
      company_name: string,
      company_type: string,
      contact_person_name: string,
      contact_person_designation: string,
      contact_person_mobile: string,
      company_state: string,
      company_city: string,
      status: string,
      is_duplicate_company: number,
    }

    const price_modal = (price_template_id) => {
      const modal = new Modal(document.getElementById("kt_price_modal"));
      modal.show();
      (document.querySelector('#price_template_id') as HTMLInputElement).value = price_template_id;
    }
    
    const ListOPen=  () =>{
        if(formDataViewValue.value.cust_select == '0'){
          getList(searchTearm.value)
        }
        if(formDataViewValue.value.cust_select == '1'){
          getVerifiedList(searchTearmV.value)
        }
        if(formDataViewValue.value.cust_select == '2'){
          getUnverifiedList(searchTearmU.value)
        }
    }

    const refresh=  () =>{
        if(formDataViewValue.value.cust_select == '0'){
          refreshData()
        }
        if(formDataViewValue.value.cust_select == ''){
          refreshData()
        }
        if(formDataViewValue.value.cust_select == '1'){
          refreshDataV()
        }
        if(formDataViewValue.value.cust_select == '2'){
          refreshDataU()
        }
    }

    var paginationData = ref({})
    
    paginationData.value = {      
         start : 0,
          end : 0,
          total: 0,        
        perPage : 5,
        activePage : 1,
        totPage : 1,        
        pageRange : [1],
        page_select: 1
    }

    const refreshData =() => {      
      paginationData.value['activePage']  = 1;  
      paginationData.value['page_select'] = 1;  
      getList(searchTearm.value)
    }

    const changePageChange = (page) => {      
      paginationData.value['activePage']  = page;      
      getList(searchTearm.value)
      if(paginationData.value['totPage'] == page){
      paginationData.value['page_select'] = page
      }
      if(paginationData.value['activePage'] == 1){
      paginationData.value['page_select'] = page
      }
      
    }

    var paginationDataV = ref({})
    
    paginationDataV.value = {      
         start : 0,
          end : 0,
          total: 0,        
        perPage : 5,
        activePage : 1,
        totPage : 1,        
        pageRange : [1],
        page_select: 1
    }

    const refreshDataV =() => {      
      paginationDataV.value['activePage']  = 1;   
      paginationDataV.value['page_select'] = 1;
      getVerifiedList(searchTearmV.value)
    }

    const changePageChangeV = (page) => {      
      paginationDataV.value['activePage']  = page;      
      getVerifiedList(searchTearmV.value)
      if(paginationDataV.value['totPage'] == page){
      paginationDataV.value['page_select'] = page
      }
      if(paginationDataV.value['activePage'] == 1){
      paginationDataV.value['page_select'] = page
      }
    }

    var paginationDataU = ref({})
    
    paginationDataU.value = {      
         start : 0,
          end : 0,
          total: 0,        
        perPage : 5,
        activePage : 1,
        totPage : 1,        
        pageRange : [1],
        page_select: 1
    }

    const refreshDataU =() => {      
      paginationDataU.value['activePage']  = 1;    
      paginationDataU.value['page_select'] = 1;  
      getUnverifiedList(searchTearmU.value)
    }

    const changePageChangeU = (page) => {      
      paginationDataU.value['activePage']  = page;      
      getUnverifiedList(searchTearmU.value)
      if(paginationDataU.value['totPage'] == page){
      paginationDataU.value['page_select'] = page
      }
      if(paginationDataU.value['activePage'] == 1){
      paginationDataU.value['page_select'] = page
      }
    }
    
    var tableData = ref<Array<Grade>>([]);
    var tableDataV = ref<Array<Grade>>([]);
    var tableDataU = ref<Array<Grade>>([]);


    const getVerifiedList = async (data) => {
      
       loadingData.value = true;

      try {

        var values = { 
          "search_term" : data,
          "verified" : formDataViewValue.value.cust_select,
          "page"  : parseInt(paginationDataV.value['activePage']) || parseInt(paginationDataV.value['page_select']), 
          "records_per_page" : parseInt(paginationDataV.value['perPage']) }
        
        await store.dispatch(Actions.CUST_LIST_ENTITY_DATA, values).then(({ data }) => {

          console.log(data);
          
          tableDataV.value = ([]);
          if(data != 0){
          // set pagination
          paginationDataV.value['total'] = data.total_records;        
          paginationDataV.value['start'] = data.records_from
          paginationDataV.value['end'] = data.records_upto
          paginationDataV.value['activePage'] = data.page;
          paginationDataV.value['totPage']  = data.total_pages
          paginationDataV.value['pageRange'] = []
          for (let index =  data.bar_range_start; index < data.bar_range_end; index++) {
            paginationDataV.value['pageRange'].push(index)
          }
          //endpagination
          console.log("paginationData.value")
          console.log(paginationDataV.value)
          var resultsM = ref<Array<Grade>>([])
          
          for (let j = 0; j < data.result_list.length; j++) {

            resultsM.value = Array({
              record_id : data.result_list[j]['record_id'],
              company_type: data.result_list[j]['company_type'],
              company_name: data.result_list[j]['company_name'],
              contact_person_name: data.result_list[j]['contact_person_name'],
              contact_person_designation: data.result_list[j]['contact_person_designation'],
              contact_person_mobile: data.result_list[j]['contact_person_mobile'],
              company_state: data.result_list[j]['company_state'],
              company_city: data.result_list[j]['company_city'],
              status : data.result_list[j]['status'],
              is_duplicate_company : data.result_list[j]['is_duplicate_company'],
            })

            tableDataV.value.splice(j, resultsM.value.length, ...resultsM.value);            
          }

          loadingData.value = false;
          }
          if(data == 0){
            loadingData.value = false;
            console.log("EMPTY DATA")
            // set pagination
          paginationDataV.value['total'] = 0;        
          paginationDataV.value['start'] = 0
          paginationDataV.value['end'] = 0
          paginationDataV.value['activePage'] = 1;
          paginationDataV.value['totPage']  = 0
          paginationDataV.value['pageRange'] = [1]
          for (let index =  data.bar_range_start; index < data.bar_range_end; index++) {
            paginationDataV.value['pageRange'].push(index)
          }
         // paginationDataV.value['page_select'] = 0
          //endpagination
          }
        }).catch(({ response }) => {

          tableDataV.value = [];
          loadingData.value = false;

       });

      } catch (e) {
        console.log(e);
      }
    };

     const getUnverifiedList = async (data) => {
      
       loadingData.value = true;

      try {

        var values = { 
          "search_term" : data,
          "verified" : formDataViewValue.value.cust_select,
          "page"  : parseInt(paginationDataU.value['activePage']) || parseInt(paginationDataU.value['page_select']), 
          "records_per_page" : parseInt(paginationDataU.value['perPage']) }
        
        await store.dispatch(Actions.CUST_LIST_ENTITY_DATA, values).then(({ data }) => {

          console.log(data);
          
          tableDataU.value = ([]);
          if(data !=0){
          // set pagination
          paginationDataU.value['total'] = data.total_records;        
          paginationDataU.value['start'] = data.records_from
          paginationDataU.value['end'] = data.records_upto
          paginationDataU.value['activePage'] = data.page;
          paginationDataU.value['totPage']  = data.total_pages
          paginationDataU.value['pageRange'] = []
          for (let index =  data.bar_range_start; index < data.bar_range_end; index++) {
            paginationDataU.value['pageRange'].push(index)
          }
          //endpagination
          console.log("paginationData.value")
          console.log(paginationDataU.value)
          var resultsM = ref<Array<Grade>>([])
          
          for (let j = 0; j < data.result_list.length; j++) {

            resultsM.value = Array({
              record_id : data.result_list[j]['record_id'],
              company_type: data.result_list[j]['company_type'],
              company_name: data.result_list[j]['company_name'],
              contact_person_name: data.result_list[j]['contact_person_name'],
              contact_person_designation: data.result_list[j]['contact_person_designation'],
              contact_person_mobile: data.result_list[j]['contact_person_mobile'],
              company_state: data.result_list[j]['company_state'],
              company_city: data.result_list[j]['company_city'],
              status : data.result_list[j]['status'],
              is_duplicate_company : data.result_list[j]['is_duplicate_company'],
            })

            tableDataU.value.splice(j, resultsM.value.length, ...resultsM.value);            
          }

          loadingData.value = false;
        }
        if(data == 0){
          loadingData.value = false;
            console.log("EMPTY DATA")
            // set pagination
          paginationDataU.value['total'] = 0;        
          paginationDataU.value['start'] = 0
          paginationDataU.value['end'] = 0
          paginationDataU.value['activePage'] = 1;
          paginationDataU.value['totPage']  = 0
          paginationDataU.value['pageRange'] = [1]
          for (let index =  data.bar_range_start; index < data.bar_range_end; index++) {
            paginationDataU.value['pageRange'].push(index)
          }
         // paginationDataU.value['page_select'] = 0
          //endpagination
          }
        }).catch(({ response }) => {

          tableDataU.value = [];
          loadingData.value = false;

       });

      } catch (e) {
        console.log(e);
      }
    };
  
     const getList = async (data) => {
      
       loadingData.value = true;

      try {

        var values = { 
          "search_term" : data,
          "page"  : parseInt(paginationData.value['activePage']) || parseInt(paginationData.value['page_select']), 
          "records_per_page" : parseInt(paginationData.value['perPage']) }
        console.log(values)
        await store.dispatch(Actions.CUST_LIST_ENTITY_DATA, values).then(({ data }) => {

          console.log(data);
          
          tableData.value = ([]);
          if(data != 0){
          // set pagination
          paginationData.value['total'] = data.total_records;        
          paginationData.value['start'] = data.records_from
          paginationData.value['end'] = data.records_upto
          paginationData.value['activePage'] = data.page;
          paginationData.value['totPage']  = data.total_pages
          paginationData.value['pageRange'] = []
          for (let index =  data.bar_range_start; index < data.bar_range_end; index++) {
            paginationData.value['pageRange'].push(index)
          }
          //endpagination
          console.log("paginationData.value")
          console.log(paginationData.value)
          var resultsM = ref<Array<Grade>>([])
          
          for (let j = 0; j < data.result_list.length; j++) {

            resultsM.value = Array({
              record_id : data.result_list[j]['record_id'],
              company_type: data.result_list[j]['company_type'],
              company_name: data.result_list[j]['company_name'],
              contact_person_name: data.result_list[j]['contact_person_name'],
              contact_person_designation: data.result_list[j]['contact_person_designation'],
              contact_person_mobile: data.result_list[j]['contact_person_mobile'],
              company_state: data.result_list[j]['company_state'],
              company_city: data.result_list[j]['company_city'],
              status : data.result_list[j]['status'],
              is_duplicate_company : data.result_list[j]['is_duplicate_company'],

            })

            tableData.value.splice(j, resultsM.value.length, ...resultsM.value);            
          }

          loadingData.value = false;
          }
          if(data == 0){
            loadingData.value = false;
            console.log("EMPTY DATA")
            // set pagination
          paginationData.value['total'] = 0;        
          paginationData.value['start'] = 0
          paginationData.value['end'] = 0
          paginationData.value['activePage'] = 1;
          paginationData.value['totPage']  = 0
          paginationData.value['pageRange'] = [1]
          for (let index =  data.bar_range_start; index < data.bar_range_end; index++) {
            paginationData.value['pageRange'].push(index)
          }
          paginationData.value['page_select'] = 0
          //endpagination
          }
        }).catch(({ response }) => {

          tableData.value = [];
          loadingData.value = false;

       });

      } catch (e) {
        console.log(e);
      }
    };

      onUpdated(() => {          
        // console.log("aaap");
        // console.log(list_data);
      });

      const initCustomers = ref<Array<Grade>>([]);                      

      onMounted( async () => {
        await getList(searchTearm.value)
        setCurrentPageBreadcrumbs("Upload Entity Data", []);
        initCustomers.value.splice(0, tableData.value.length, ...tableData.value);        
        console.log(tableData.value.length);
      });  

      const deleteFewCustomers = () => {
        checkedCompany.value.forEach((item) => {
          deleteCustomer(item);
        });
        checkedCompany.value.length = 0;
      };

      const deleteCustomer = (id) => {
        for (let i = 0; i < tableData.value.length; i++) {
          if (tableData.value[i].record_id === id) {
            tableData.value.splice(i, 1);
          }
        }
      };

      const search = ref<string>("");
       const searchItems = () => {
          searchTearm.value = search.value;
          refreshData();
          getList(search.value);
      };

      const searchV = ref<string>("");
       const searchItemsV = () => {
          searchTearmV.value = search.value;
          refreshDataV();
          getVerifiedList(searchV.value);
          
      };

      const searchU = ref<string>("");
       const searchItemsU = () => {
          searchTearmU.value = searchU.value;
          refreshDataU();
          getUnverifiedList(searchU.value);
          
      };

      const searchingFunc = (obj, value): boolean => {        
        for (let key in obj) {          
          if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {            
            if (obj[key].indexOf(value) != -1) {
              
              return true;
            }
          }
        }
        return false;
      };       
      
      interface ViewCategories {
        record_id,
        company_name,
        company_type,
        contact_person_name,
        contact_person_designation,
        contact_person_mobile,
        contact_person_email,
        company_city,
        company_state,
        company_pincode,
        company_website,
        company_nob_name,
        designation_name,
       is_duplicate_company,
    };    
    
    var resultsView = ref<ViewCategories>();
  
    resultsView.value = {
      record_id: "",
      company_name: "",
      company_type: "",
      contact_person_name: "",
      contact_person_designation: "",
      contact_person_mobile: "",
      contact_person_email:"",
      company_city: "",
      company_state: "",
      company_pincode: "",
      company_website: "",
      company_nob_name: "",
      designation_name: "",
      is_duplicate_company: "",
    }

    const view_modal = (record_id) => {
      const modal = new Modal(
        document.getElementById("kt_modal_product_view_attr_setting_value")
      );
      modal.show();
      (document.querySelector("#value_tbl2") as HTMLInputElement).value =
        record_id;
      getGradeDetails();
    };

    var tableData2 = ref<Array<ViewCategories>>([]);
    const getGradeDetails = async () => {
     
      try {

      var values = { "record_id"  :  (document.querySelector("#value_tbl2") as HTMLInputElement).value}

      await store.dispatch(Actions.CUST_VIEW_ENTITY_DATA, values).then(({ data }) => {
       tableData2.value = [];
        
      // for (let j = 0; j < data.length; j++) {
        resultsView.value = {
          record_id : data.record_id,
          company_name : data.company_name,
          company_type : data.company_type,
          contact_person_name : data.contact_person_name,
          contact_person_designation : data.contact_person_designation,
          contact_person_mobile : data.contact_person_mobile,
          contact_person_email : data.contact_person_email,
          company_city : data.company_city,
          company_state : data.company_state,
          company_pincode : data.company_pincode,
          company_website : data.company_website,
          company_nob_name : data.company_nob_name,
          designation_name : data.designation_name,
          is_duplicate_company : data.is_duplicate_company,
        }
          //tableData2.value.splice(j, resultsView.value.length, ...resultsView.value);

      // }
      console.log(resultsView)

      })
      .catch(({ response }) => {
        console.log(response);
      });
        
      } catch (e) {
        console.log(e);
      }
    };     
    
       
    return {
      tableData,
      tableData2,  
      tableDataV,  
      tableDataU,  
      deleteCustomer,
      search,
      searchV,
      searchU,
      searchItems,
      searchItemsV,
      searchItemsU,
      checkedCompany,
      deleteFewCustomers,
      price_modal,
      product_attr_modal,
      count,      
      getList,    
      getVerifiedList,   
      getUnverifiedList,
      ListOPen,
      refresh,
      paginationData,
      changePageChange,
      refreshData,
      loadingData,
      getGradeDetails,
      view_modal,
      viewDataModalRef,
      formDataViewValue,
      resultsView,
      status_list_data,
      paginationDataV,
      changePageChangeV,
      refreshDataV,
      paginationDataU,
      changePageChangeU,
      refreshDataU,
    };  

  }  

});
