
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import {ActionsRe} from "@/store/enums/StoreResearchEnums";
import JwtService from "@/core/services/JwtService";

export default defineComponent({
  name: "add-product-modal",
  components: {
    
    },
  
  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const addImageModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const store = useStore();
    const router = useRouter(); 

    onMounted( async () => {
      //const db_data = {}
     
    });
    

    const formData = ref({
      name: "",
    });    

    const rules = ref({
      
    });

    let rawdoc = ref() ;
    function encodeDoctoBase64L(element) {
        const fileupload = element.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(fileupload);
        reader.onload = (event) => {       
        var image_encode = String(reader.result)
        if (image_encode){
           var image_slice = image_encode.split(",")[1]
            rawdoc.value = {
         // file_name:element.target.files[0].name,
          file_data: image_slice,
          }
        }
        else{
          rawdoc.value = {
          file_name:element.target.files[0].name,
          file_data:reader.result,
          }
        }
       };
        
      }

    const UploadImage = async (data) => {
      var user = JSON.parse(JwtService.getToken());
      const db_data = {
       // "file_name" : rawdoc.value.file_name,
        "file_data" : rawdoc.value.file_data,
        "user_id" : user.user_id,
        }
          await store.dispatch(Actions.CUST_UPLOAD_ENTITY_DATA, db_data).then(({ data }) => {
           if (data.is_success){
        
            setTimeout(() => {
              loading.value = false;
//
              Swal.fire({
                text: "File uploaded successfully.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addImageModalRef.value);  
                router.go(0)           
              });
            }, 2000)          

          } else {

            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }
      
      })
      .catch(({ response }) => {
        console.log(response);        
      });
    }

    const submit = async () => {

      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate (async (valid) => {

        if (valid) {

          await UploadImage(formData.value);
        
        } else {

          loading.value = false;
          return false;
        }
      });    
    };


    return {      
      formData,      
      rules,
      submit,
      formRef,
      loading,
      addImageModalRef,
      encodeDoctoBase64L,
      rawdoc,
    };
  },
});
